import React, { useEffect } from "react";
import Slider from "react-slick";

import ServiceCard from "./Components/ServiceCard";

//  import images
import image1 from "./../../assets/brands/brand-1.png";
import image2 from "./../../assets/brands/brand-2.png";
import image3 from "./../../assets/brands/brand-3.png";
import image4 from "./../../assets/brands/brand-4.png";
import image5 from "./../../assets/brands/brand-5.png";
import image6 from "./../../assets/brands/brand-6.png";
import image7 from "./../../assets/brands/brand-7.png";
// import image1 from "./../../assets/brands/brand-2.png";

import aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const service = [
  {
    title: "360 Life-Biz coaching",
    link: "360_Life_Biz_coaching",
    description: `A one-stop solution for all your needs, starting from your business to your lifestyle.`,
  },
  {
    title: "SME consultancy solution",
    link: "SME_consultancy_solution",
    description: `A fully customisable business strategy to transform your organization`,
  },
  {
    title: "Corporate leadership training",
    link: "Corporate_leadership_training",
    description: `An in depth training program to help scale up your leadership team`,
  },
  {
    title: "Business seminar",
    link: "Business_seminar",
    description: `A tailor made seminar to unlock one’s individual potential`,
  },
];

function Services() {
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-5 py-5 " data-aos="fade-in">
      <div
        className=" md:mb-24 md:mt-4 mt-0 brand-slider "
        data-aos="fade-in"
        data-aos-delay="300"
      >
        <Slider {...settings}>
          <img
            src={image1}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          <img
            src={image2}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          <img
            src={image3}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          <img
            src={image4}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          <img
            src={image5}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          <img
            src={image6}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          <img
            src={image7}
            className=" grayscale md:w-16 md:px-10 px-3 flex justify-center mx-auto my-auto"
          />
          {/* <img src={image8} className=" grayscale md:w-10 md:px-4 px-3 flex justify-center mx-auto my-auto" /> */}
        </Slider>
      </div>

      <div
        className="md:w-9/12 w-11/12 mx-auto"
        data-aos="fade-in"
        data-aos-delay="500"
      >
        <div className="flex flex-col gap-1 items-cent justify-center md:relative sticky top-0 py-1 pt-0 bg-black z-20">
          <div className="md:mx-auto">
            <div className="text-gold tracking-widest text-sm md:text-base space5 uppercase wide10px mb-1 ">
              {" "}
              What i do ?
            </div>
            <h2 className="md:text-5xl text-2xl uppercase tracking-wide wide10px text-white">
              {" "}
              My Services{" "}
            </h2>
          </div>
        </div>
        <div
          className="grid md:grid-cols-2 md:w-[85%] md:mx-auto grid-cols-1 md:gap-8 gap-4  md:mt-8 mt-3"
          data-aos="fade-in"
          data-aos-delay="600"
        >
          {service.map((item, index) => (
            <Link to={`/${item.link}`}>
              <ServiceCard
                key={index}
                data={item}
                action={true}
                delay={index * 30}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;

// <Slider {...settings}>
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image1} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image2} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image3} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image4} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image5} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image6} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image7} className=" grayscale" />
// {/* </div> */}
// {/* <div className="border-2 border-gray-900 flex justify-center items-center py-6 px-14"> */}
//   <img src={image8} className=" grayscale" />
// {/* </div> */}
//         </Slider>
