import React, { useEffect } from "react";
import Header from "../Header";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import { GrDown } from "react-icons/gr";

// import video from "./../../assets/Maldives_Floating_City_short_version.mp4";
import ReactPlayer from "react-player";

import aos from "aos";
import "aos/dist/aos.css";
import Header2 from "../Header2";

function HiroAboutPage({ video, img, tag }) {
  useEffect(() => {
    aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="relative h-[100vh] bg-black-300 mb-20 md:mb-0 md:max-h-[100vh] max-h-[100vh] overflow-hidden">
      <Header2  burger={false} />

      <div className="md:h-[100vh] h-[100vh] w-full flex justify-center items-center relative z-30">
        <div className="content md:w-[50%] w-[80%] text-center h-auto justify-center flex items-center flex-col gap-3 relative z-20">
          <h1
            className=" HiroMainText tracking-wide text-white font-extrabold md:tracking-widest"
            data-aos="fade-up"
            data-aos-delay="1000"
          >
            ABOUT ME
          </h1>
          <h3
            className="text-gold soheilText"
            data-aos="fade-up"
            data-aos-delay="1300"
          >
            {" "}
            SOHEIL MARJANI{" "}
          </h3>
        </div>

        <div className=" absolute bottom-4 animate-bounce text-gold z-50 cursor-pointer">
          <ScrollLink
            to={tag}
            duration={500}
            className=" text-white hover:scale-125 transition-all duration-300 text-3xl cursor-pointer"
            spy={true}
            smooth={true}
          >
            <GrDown color="green" size={50} className="downIcon" />{" "}
          </ScrollLink>
        </div>
        <div className=" absolute top-0 bottom-0 left-0 right-0 bg-black md:bg-opacity-60 bg-opacity-80"></div>
      </div>
      <div className=" absolute md:top-auto md:bottom-0 top-0  md:w-screen ">
        {img ? (
          <div className=" bg-black w-screen h-screen " style={{ backgroundImage : 'url(' +img+')' , backgroundSize:'cover' , backgroundPosition : 'center center' }}></div>

          // <img src={img} alt="" className="h-screen w-[100%] md:scale-[1] scale-x-[2.2]" />
        ) : (
          <video src={video} autoPlay muted loop />
        )}
      </div>
    </div>
  );
}

export default HiroAboutPage;
