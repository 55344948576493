import React, { useEffect } from "react";
import Header from "../Header";

import { GrDown } from "react-icons/gr";

// import video from "./../../assets/Maldives_Floating_City_short_version.mp4";
import ReactPlayer from "react-player";

import aos from "aos";
import "aos/dist/aos.css";

function HiroConsultPage({ video }) {
  useEffect(() => {
    aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="relative h-[100vh] bg-black-300 mb-20 md:mb-0 md:max-h-[100vh] max-h-[100vh] overflow-hidden">
      <Header />

      <div className="md:h-[100vh] h-[100vh] w-full flex justify-center items-center relative z-30">
        <div className="content md:w-[50%] w-[80%] text-center h-auto justify-center flex items-center flex-col gap-3 relative z-20">
          <h1
            className=" HiroMainText text-white font-extrabold md:tracking-widest"
            data-aos="fade-up"
            data-aos-delay="1000"
          >
            CONSULTING
          </h1>
          <h3
            className="text-gold soheilText txet-xl"
            data-aos="fade-up"
            data-aos-delay="1300"
          >
            SOHEIL MARJANI
          </h3>
        </div>
        <div className=" absolute bottom-4 animate-bounce text-gold z-50">
          {" "}
          <GrDown color="green" size={50} className="downIcon" />{" "}
        </div>
        <div className=" absolute top-0 bottom-0 left-0 right-0 bg-black md:bg-opacity-60 bg-opacity-80"></div>
      </div>
      <div className=" absolute md:top-auto md:bottom-0 top-0  md:w-full ">
        <video src={video} autoPlay muted loop />
      </div>
    </div>
  );
}

export default HiroConsultPage;
