import React, { useEffect } from "react";
import SmallButton from "../../../Componenets/SmallButton";

import aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function ServiceCard(props) {
  const { key, data, action, delay } = props;
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      {/* <Link to={`/${data.link}`}> */}
      <div
        data-aos="zoom-in"
        data-aos-delay={`${delay + 50}`}
        key={key}
        className=" 
      serviceBox
      relative
      pb-20
      border-2 border-gray-800 
      p-6 border-l-8 hover:border-l-gold border-l-gray-800 transition-all 
    hover:shadow-slate-800 md:h-52 h-48"
      >
        <h4 className=" text-white text-xl md:text-2xl capitalize">
          {data.title}
        </h4>
        <p className="text-sm md:text-base text-white mt-3 opacity-75 text-justify">
          {data.description}
        </p>
        {action && (
          <Link to={`/${data.link}`} className="absolute bottom-8">
            <SmallButton text="Learn More" subtext="Click ->" />
          </Link>
        )}
      </div>
      {/* </Link> */}
    </div>
  );
}

export default ServiceCard;
