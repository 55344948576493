import React from "react";

import { BsArrowRight } from "react-icons/bs";

function BigButton({ text }) {
  return (
    <button class="Mybutton button--wayra button--border-thick button--text-upper button--size-s mt-3">
      <div className="flex mx-auto items-center gap-3 md:px-0 px-10">
        {text}
        <BsArrowRight size={20} />
      </div>
    </button>
  );
}

export default BigButton;
