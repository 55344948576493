import React from "react";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FiLinkedin } from "react-icons/fi";
import { FiFacebook, FiTwitter } from "react-icons/fi";

import sohei_White from "./../../assets/images/SOHEIL SIG 1.svg";

const phoneNumber = "+60173009175";
const text = "";
function Footer() {
  return (
    <div>
      <div className="top-footer flex gap-3 md:gap-5 mt-10 md:mt-0 flex-col md:flex-row justify-center items-center py-[3%] border-y-2 border-gray-900">
        <div className="flex flex-col gap-3 text-gold md:items-start items-center wide5px text-sm md:w-[30%] w-full">
          <a href="mailto:soheil.coaching@gmail.com?subject=free" className="uppercase">
           soheil.coaching@GMAIL.COM
          </a>
          <a href="tel:+60173009175">
            <span>+60 17 300 9175 </span>
          </a>
        </div>
        <div></div>
        <div className="md:w-[20%] w-[50%] flex justify-center items-center">
          <img src={sohei_White} className="h-12" />
        </div>
        <div></div>
        <div className="flex justify-center items-center gap-8 md:w-[30%] w-[50%]">
          {/* <BsInstagram size={25} className='text-gold' /> */}
          {/* <FiTwitter size={25} className='text-gold' /> */}
          {/* <FiFacebook size={25} className='text-gold' /> */}
          <a href={`whatsapp://send?text=${text}&phone=${phoneNumber}`}>
            <AiOutlineWhatsApp size={25} className="text-gold" />
          </a>
          <a target="_blank" href="https://my.linkedin.com/in/soheilmarjani">
            <FiLinkedin size={25} className="text-gold" />
          </a>
        </div>
      </div>
      <div className="bottom-footer text-center text-white py-8 text-sm ">
        {/* <p> @2022 by <span className="text-gold mx-1"> Ali Marjani </span> . Proudly created with Wix.com </p> */}
        <div className="flex justify-center text-gold text-sm md:gap-8 gap-3  mt-3">
          <span className=""> Terms of Service </span>
          <span className=""> security and policy </span>
          {/* <span className=""> Terms of Service </span> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
