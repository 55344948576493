import React, { useEffect } from "react";

import { BsArrowRight } from "react-icons/bs";
import BigButton from "../../Componenets/BigButton";

import aos from "aos";
import "aos/dist/aos.css";

const Coach =
  "https://img.freepik.com/premium-photo/coaching-trainingsplanning_36325-69.jpg?w=2000";

function Project() {
  useEffect(() => {
    aos.init({ duration: 2000 });
  }, []);
  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1 gap-8 pt-[20%]"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      <div>
        <div className="md:h-[150px] h-[120px] ">
          <div className="flex flex-col gap-1 items-start justify-center">
            <div className="text-gold tracking-widest text-lg uppercase space5">
              {" "}
              Work that I’ve done{" "}
            </div>
            <h2 className="md:text-5xl text-4xl tracking-wide text-white uppercase md:mt-3 mt-1">
              {" "}
              Recent Projects{" "}
            </h2>
          </div>
        </div>

        <div
          className=" border-2 border-gray-800 md:mt-8 mt-1 relative card-project"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-mirror="true"
        >
          <div
            className=" w-full h-80"
            style={{
              backgroundImage: "url(" + Coach + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="flex flex-col md:px-8 px-4 md:pb-8 pb-4 pt-4">
            <h4 className="text-2xl text-white capitalize"> coaching 1 </h4>
            <p className="text-white text-sm mt-3">
              I worked with the guys at CBRE to redesign their entire website
              and mobile app fro both Android and iOS. This project lasted for 4
              months and was a very challenging one.
            </p>
            <div className="text-gold text-sm mt-4 opacity-70 hover:opacity-100 wide5px ">LEARN MORE</div>
          </div>
        </div>
        <div
          className=" border-2 border-gray-800 mt-8 card-project"
          data-aos="fade-up"
          data-aos-delay="700"
          data-aos-mirror="true"
        >
          <div
            className=" w-full h-80"
            style={{
              backgroundImage: "url(" + Coach + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="flex flex-col md:px-8 px-4 md:pb-8 pb-4 pt-4">
            <h4 className="text-2xl text-white capitalize"> coaching2 </h4>
            <p className="text-white text-sm mt-3">
              I worked with the guys at CBRE to redesign their entire website
              and mobile app fro both Android and iOS. This project lasted for 4
              months and was a very challenging one.
            </p>
            <div className="text-gold text-sm mt-4 opacity-70 hover:opacity-100 wide5px ">LEARN MORE</div>
          </div>
        </div>
      </div>

      <div data-aos="fade-up" data-aos-delay="100" data-aos-mirror="true">
        <div className=" border-2 border-gray-800 card-project-right ">
          <div
            className=" w-full h-80"
            style={{
              backgroundImage: "url(" + Coach + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="flex flex-col md:px-8 px-4 md:pb-8 pb-4 pt-4 ">
            <h4 className="text-2xl text-white capitalize"> coaching3 </h4>
            <p className="text-white text-sm mt-3">
              I worked with the guys at CBRE to redesign their entire website
              and mobile app fro both Android and iOS. This project lasted for 4
              months and was a very challenging one.
            </p>
            <div className="text-gold text-sm mt-4 opacity-70 hover:opacity-100 wide5px ">LEARN MORE</div>
          </div>
        </div>

        <div
          className=" border-2 border-gray-800 mt-8 card-project-right"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-mirror="true"
        >
          <div
            className=" w-full h-80"
            style={{
              backgroundImage: "url(" + Coach + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="flex flex-col md:px-8 px-4 md:pb-8 pb-4 pt-4">
            <h4 className="text-2xl text-white capitalize"> coaching4 </h4>
            <p className="text-white text-sm mt-3">
              I worked with the guys at CBRE to redesign their entire website
              and mobile app fro both Android and iOS. This project lasted for 4
              months and was a very challenging one.
            </p>
            <div className="text-gold text-sm mt-4 opacity-70 hover:opacity-100 wide5px ">LEARN MORE</div>
          </div>
        </div>

        <div className="md:mt-20 mt-10 mb-20 md:mb-0 flex justify-center md:scale-125">
          <BigButton text="More Project" />
        </div>
      </div>
    </div>
  );
}

export default Project;
