import React, { useEffect } from "react";
import HiroService from "./HiroService";

import Aboutme_Image from "./../../assets/images/architects-discussing-construction-plan-drawings 1.png";
import Hiro_Image from "./../../assets/image-soheil/IMG_0088.JPG";
import Footer from "../Home/Footer";
import ContactMe from "../Home/ContactMe";
import ServiceCard from "../Home/Components/ServiceCard";

import aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

const steps = [
  {
    title: "step1",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step2",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step3",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
];

function Business_seminar({ video }) {
  useEffect(() => {
    aos.init({ duration: 1000 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div data-aos="fade-down">
      <HiroService img={Hiro_Image} title={"Business seminar"} tag='bs' />
      <div className="md:w-9/12 w-11/12 mx-auto text-center md:pt-32 pt-8" id="bs">
        <div className="img-container flex-col items-center md:my-8 my-0 md:w-8/12 w-11/12 mx-auto flex justify-center">
          <div className="md:mb-8 mb-0 ">
            <p
              className="text-gold md:mb-16 mb-6 text-lg md:text-justify text-left capitalize "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              A tailor made seminar to unlock one’s individual potential
            </p>
            <p
              className="text-white mb-8 md:text-base text-sm text-justify "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Success goes hand in hand with a strong desire to reach your
              goals, which is why a business seminar can be the best chance you
              have to get ahead in your career. By surrounding yourself with
              like-minded people who are willing to help you, you can gain
              valuable insight into ways to not only survive but to thrive.
              Here, you will be able to envision yourself as a future leader in
              your company by planting the characteristics, skills, and
              understanding that need to be established in a workplace.
            </p>
            <p
              className="text-white md:text-base text-sm text-justify"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Examine the effective leadership techniques, the methods of
              communication, and the building of trust and confidence levels in
              your team while learning the behavioural qualities and traits that
              project professionalism and accountability as a leader. You'll
              also pick up on techniques for building rapport with new people
              quickly and adapting to the various personalities you'll
              encounter.
            </p>
          </div>
          {/* <img
            data-aos="fade-up"
            data-aos-delay="200"
            className="mx-auto w-full"
            src={Aboutme_Image}
          />

          <div className="w-0 md:h-96 h-40 border-l-2 border-white border-opacity-60" /> */}
        </div>
        {/* <div className="md:w-10/12 w-11/12 mx-auto">
          <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-y-4  text-left">
            {steps.map((item, index) => (
              <ServiceCard key={index} data={item} action={false} />
            ))}
          </div>
        </div> */}
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%] md:mt-0 mt-16">
        <ContactMe />
      </div>

      <Footer />
    </div>
  );
}

export default Business_seminar;
