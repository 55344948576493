import React, { useEffect } from "react";
import HiroConsultPage from "./HiroConsultPage";

import Aboutme_Image from "./../../assets/images/architects-discussing-construction-plan-drawings 1.png";
import Footer from "../Home/Footer";
import ContactMe from "../Home/ContactMe";
import ServiceCard from "../Home/Components/ServiceCard";

import aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

const steps = [
  {
    title: "step1",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step2",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step3",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
];

function Consulting({ video }) {
  useEffect(() => {
    aos.init({ duration: 2000 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div data-aos="fade-down">
      <HiroConsultPage video={video} />
      <div className="md:w-9/12 w-11/12 mx-auto text-center mt-32">
        <div className="img-container flex-col items-center my-8 md:w-8/12 w-11/12 mx-auto flex justify-center">
          <div className="mb-8 ">
            <h2
              className="text-gold wide10px md:text-5xl text-4xl mb-10 uppercase space5"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              {" "}
              Working with Me{" "}
            </h2>
            <p
              className="text-white text-lg wide5px capitalize "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Let's be honest and cut through the marketing fluff. You need a
              website that looks amazing and actually works. The bottom line,
              that's what I do. And if you want to learn more about working with
              me, connect with me today.Let's be honest and cut through the
              marketing fluff. You need a website that looks amazing and
              actually works. The bottom line, that's what I do. And if you want
              to learn more about working with me, connect with me today. Let's
              be honest and cut through the marketing fluff. You need a website
              that looks amazing and actually works. The bottom line, that's
              what I do. And if you want to learn more about working with me,
              connect with me today.Let's be honest and cut through the
              marketing fluff. You need a website that looks amazing and
              actually works. The bottom line, that's what I do. And if you want
              to learn more about working with me, connect with me today.
            </p>
          </div>
          <img
            data-aos="fade-up"
            data-aos-delay="200"
            className="mx-auto w-full"
            src={Aboutme_Image}
          />

          <div className="w-0 md:h-96 h-40 border-l-2 border-white border-opacity-60" />

          <div
            className="other w-full mt-2"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <h3
              className="text-gold text-4xl text-left uppercase"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              My Process
            </h3>
            <p
              className=" text-white text-lg wide5px text-left md:mt-6 mt-2"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              I work differently than most creatives. I don't just turn out
              multiple design mock-ups at you and hope you like one. I first
              learn who you are and how you got started. It’s a simple fact that
              my clients invest a lot in me, and it only makes sense that I’m
              doing the same for them. With that said, working with me relies
              heavily on trust, and letting me do what I do best. And this is
              why I first go through a deep interview process to fully
              understand your needs within the structure, as well as, the
              aesthetic of the website. Because every one of my clients needs
              something different, so let me build the site you need, not what
              someone else needed.
            </p>
          </div>
        </div>
        <div className="md:w-10/12 w-11/12 mx-auto">
          <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-y-4  text-left">
            {steps.map((item, index) => (
              <ServiceCard key={index} data={item} action={false} />
            ))}
          </div>
        </div>
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%]">
        <ContactMe />
      </div>

      <Footer />
    </div>
  );
}

export default Consulting;
