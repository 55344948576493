import React, { useEffect, useRef } from "react";
import AboutMe from "./AboutMe";
import Hiro from "./Hiro";

// import Aboutme_Image from "./../../assets/images/WhatsApp Image 2022-08-10 at 3.42 2.png";
import Aboutme_Image from "./../../assets/image-soheil/IMG_0054.JPG";
import Services from "./Services";
import Project from "./Project";
import ContactMe from "./ContactMe";
import Footer from "./Footer";

import aos from "aos";
import "aos/dist/aos.css";

import * as Scroll from "react-scroll";
import {
  Link as ScrollLink,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { useLocation } from "react-router-dom";

function Home(props) {
  const { innerWidth: width, innerHeight: height } = window;

  console.log(height, width);

  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

 const aboutRef =useRef();

  return (
    <div className=" relative" data-aos="fade-down">
      <div id="hiro" className="">
        <Hiro  />
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto" id="AboutMe" ref={aboutRef}>
        <AboutMe img={Aboutme_Image} aboutRef={aboutRef} />
      </div>
      <div id="Service">
        <Services />
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%]">
        {/* <div id="Projects">
          <Project />
        </div> */}
        <div id="contactMe">
          <ContactMe />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
