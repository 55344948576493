import React, { useEffect } from "react";
import { Link as Linkk } from "react-scroll";
import Header from "../Header";

import { GrDown } from "react-icons/gr";

import video from "./../../assets/hiro-home.mp4";
import ReactPlayer from "react-player";

import aos from "aos";
import "aos/dist/aos.css";

function Hiro() {
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="relative h-[100vh] bg-black-300 mb-20 md:mb-0 md:max-h-[100vh] max-h-[100vh] overflow-hidden">
        <div>
          <Header burger={true} />
        </div>
        <div className="md:h-[100vh] h-[100vh] w-full flex justify-center items-center relative z-30">
          <div className="content md:w-[100%] w-[100%] text-center h-auto justify-center flex items-center flex-col gap-2 relative z-40 ">
            <h1
              className="HiroMainText text-white font-extrabold md:tracking-widest"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {" "}
              DREAM A BIG DREAM{" "}
            </h1>
            <h3
              className="text-gold soheilText txet-xl"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {" "}
              SOHEIL MARJANI{" "}
            </h3>
            <h3
              className=" capitalize md:text-lg text-sm font-normal tracking-wider text-white"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              {" "}
              consultant & coach
            </h3>
          </div>
          <div className=" absolute bottom-4 animate-bounce text-gold z-50">
            {" "}
            <Linkk
              to="AboutMe"
              duration={500}
              className=" text-white hover:scale-125 transition-all duration-300 text-3xl cursor-pointer"
              spy={true}
              smooth={true}
              // onClick={() => setShowModal(false)}
            >
              <GrDown size={50} className="downIcon text-gol stroke-gold" />{" "}
            </Linkk>
          </div>
          <div className=" absolute top-0 bottom-0 left-0 right-0 bg-black md:bg-opacity-60 bg-opacity-80"></div>
        </div>
        <div className=" top-0 w-full h-full absolute video-style ">
          {width < 600 ? (
            // responsive video
            // <video
            //   src={video}
            //   autoplay="autoplay"
            //   muted
            //   loop="loop"
            //   defaultMuted
            //   playsinline
            //   oncontextmenu="return false;"
            //   preload="auto"
            //   id="myVideo"
            //   className="h-full respoinve-video"
            // />
            <video
              autoplay="autoplay"
              muted
              loop="loop"
              defaultMuted
              // playsinline
              playsInline
              oncontextmenu="return false;"
              preload="auto"
              id="myVideo"
              className="respoinve-video"
            >
              <source src={video} type="video/mp4" />
            </video>
          ) : (
            // desktop
            <video src={video} autoPlay muted loop className=" w-full" />
          )}
        </div>
      </div>
    </>
  );
}

export default Hiro;
