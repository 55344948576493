import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  const handleBack = () => {
    console.log('handle back')
    navigate(-1);
  };
  return (
    <>
      <div class="context">
        <h1 className=" uppercase text-xl flex flex-col gap-7 relative z-50">
          <div className=" md:text-8xl text-6xl "> 404</div>
          <div className=" leading-[80px] scale-75 md:scale-100">page not fount</div>
        </h1>
        <div className="text-center mt-20 text-white">
          <div onClick={handleBack} className="flex gap-2 justify-center">
            <HiArrowLeft size={25} />
            <button className=" uppercase text-xl"> go back </button>
          </div>
        </div>
      </div>

      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  );
}

export default ErrorPage;
