import React, { useEffect } from "react";
import HiroService from "./HiroService";

import hiro_Img from "./../../assets/image-soheil/IMG_0084.JPG";

import Footer from "../Home/Footer";
import ContactMe from "../Home/ContactMe";

import aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

const steps = [
  {
    title: "step1",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step2",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step3",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
];

function SME_consultancy_solution({ video }) {
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div data-aos="fade-down">
      <HiroService img={hiro_Img} title={"SME consultancy solution"} tag='sme' />
      <div className="md:w-9/12 w-11/12 mx-auto text-center md:pt-24 pt-8 " id="sme">
        <div className="img-container flex-col items-center md:my-8 my-0 md:w-8/12 w-11/12 mx-auto flex justify-center">
          <div className="mb-8">
            <p
              className="text-gold md:mb-16 mb-6 text-lg  md:text-justify text-left capitalize "
              // data-aos="fade-up"
              // data-aos-delay="200"
            >
              A fully customisable business strategy to transform your organization
            </p>
            <p
              className="text-white mb-8  md:text-base text-sm text-justify  "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Without proper structuring and planning, a worthy business plan is
              at risk of going awry. This is where change management plays an
              important role in project management because each change request
              must be evaluated based on its impact on the business plan. Hence,
              project managers must pay close attention to how just a slight
              change in one area of a plan could potentially affect others and
              what it would bring to the rest of it as a whole. Our SME
              consulting services are here to offer assistance to those who are
              looking to transform, not just adapt.
            </p>
            <p
              className="text-white md:text-base text-sm text-justify  "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              The beginning of envisioning your startup company expanding into a
              larger organisation starts here. You will be subjected to a
              deep-dive analysis, which is a thorough examination of your
              company's past successes, to develop a practical and realistic
              business plan for the near future. Learn the ways of establishing
              a firm management account for your business, starting now.
            </p>
            <p
              className="text-white md:text-base text-sm text-justify  "
              data-aos="fade-up"
              // data-aos-delay="100"
            >
              Win the trust of your management team, stakeholders, and even
              potential investors with the worth of your company’s vision,
              strategy, and business roadmap. This section offers a systematic
              approach for business owners to smoothly transition their
              organisation's goals into reality by providing financial analysis,
              change management, and valuable advice on business strategy,
              problem resolution, product development, and easing into
              digitalization. Lastly, utilise the risk analysis services offered
              to gain hindsight and identify potential threats to your business.
            </p>
          </div>
          {/* <img
            data-aos="fade-up"
            data-aos-delay="200"
            className="mx-auto w-full"
            src={Aboutme_Image}
          />

          <div className="w-0 md:h-96 h-40 border-l-2 border-white border-opacity-60" /> */}
        </div>
        {/* <div className="md:w-10/12 w-11/12 mx-auto">
          <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-y-4  text-left">
            {steps.map((item, index) => (
              <ServiceCard key={index} data={item} action={false} />
            ))}
          </div>
        </div> */}
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%] md:mt-0 mt-16">
        <ContactMe />
      </div>

      <Footer />
    </div>
  );
}

export default SME_consultancy_solution;
