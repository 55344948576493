import react, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import emailjs from "@emailjs/browser";

import aos from "aos";
import "aos/dist/aos.css";

function ContactMe() {
  const [formShow, setFormShow] = useState(false);
  const handleShowForm = () => {
    setFormShow(true);
  };

  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  const [email, setEmail] = useState({
    user_full_name: "",
    user_email: "",
    message: "",
  });

  const hanldeSubmitEmail = (event) => {
    event.preventDefault();
    console.log("submit");
    if (
      email.message.length > 0 &&
      email.user_full_name.length > 0 &&
      email.message.length > 0
    ) {
      emailjs.send(
        "service_ekiv8h9",
        "template_enq5d2x",
        {
          user_full_name: email.user_full_name,
          user_email: email.user_email,
          message: email.message,
        },
        "DYDu4D2Wgy7xORTwR"
      );
      setFormShow(false);
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setEmail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="py-[15%]" data-aos="fade-in">
      <div className="flex flex-col justify-center items-center">
        <div
          className="text-gold space5 uppercase wide10px text-base"
          data-aos="fade-in"
          data-aos-delay="30"
        >
          What’s next
        </div>
        <h2
          className="md:text-5xl text-2xl text-center wide10px text-white uppercase mt-3"
          data-aos="fade-in"
          data-aos-delay="100"
        >
          Lets work together
        </h2>
        <p
          className="text-sm capitalize md:text-base md:w-8/12 w-11/12 md:mb-8 mb-2 text-white text-center md:mt-8 mt-4"
          data-aos="fade-in"
          data-aos-delay="200"
        >
          If you'd like to talk about a project you want help with or need an
          advice about bussins coaching, just drop me a message at
          <a className="ml-1 font-light" href="mailto:soheil.coaching@gmail.com?subject=free">
            soheil.coaching@gmail.com .
          </a>
        </p>
        {!formShow && (
          // <div
          //   className="text-lg text-gold border-gold border-2 px-8 py-3 flex gap-3 items-center mt-12"
          //   onClick={handleShowForm}
          // >
          //   WRITE ME AN EMAIL
          //   <BsArrowRight size={20} />
          // </div>
          <button
            data-aos="fade-in"
            data-aos-delay="400"
            onClick={handleShowForm}
            class="Mybutton button--wayra button--border-thick button--text-upper button--size-s mt-3"
          >
            <div className="flex mx-auto items-center gap-3">
              WRITE ME AN EMAIL
              <BsArrowRight size={20} />
            </div>
          </button>
        )}

        {formShow && (
          <div
            className={`form md:w-[70%] w-[90%] mt-16 transition-all ${
              formShow ? "h-auto opacity-100" : "h-4 opacity-0"
            } `}
          >
            <form onSubmit={hanldeSubmitEmail}>
              <div className="w-full">
                <input
                  required
                  onChange={handleInput}
                  id="user_full_name"
                  name="user_full_name"
                  type="text"
                  placeholder="enter your name ..."
                  className=" bg-gray-900 w-full capitalize px-3 py-4 my-2 bg-opacity-60 text-white md:text-xl tex-base font-thin outline-none"
                />
              </div>
              <div className="w-full">
                <input
                  onChange={handleInput}
                  id="user_email"
                  name="user_email"
                  type="email"
                  required
                  placeholder="like : soheil.coaching@gmail.com"
                  className=" bg-gray-900 capitalize bg-opacity-60 w-full px-3 py-4 my-2  text-white md:text-xl tex-base font-thin outline-none"
                />
              </div>
              <div className="w-full">
                <textarea
                  onChange={handleInput}
                  id="message"
                  name="message"
                  required
                  type="text"
                  placeholder="enter your message to me ..."
                  rows={5}
                  className=" bg-gray-900 w-full capitalize bg-opacity-60 px-3 py-4 my-2  text-white md:text-xl tex-base font-thin outline-none"
                />
              </div>
              <div className="w-full justify-center flex">
                <div
                  className="text-lg text-black border-gold border-2 px-8 py-3 flex gap-3 items-center mt-4 bg-gold"
                  onClick={hanldeSubmitEmail}
                >
                  WRITE ME AN EMAIL
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactMe;
