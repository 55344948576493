import React from "react";

function SmallButton({ text, subtext }) {
  return (
    <div>
      <button
        class="Mybutton2 flex justify-left button--winona button--border-thin button--round-s"
        data-text={subtext}
      >
        <span>{text} </span>
      </button>
    </div>
  );
}

export default SmallButton;
