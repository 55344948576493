import react, { useEffect } from "react";
import "./App.css";
import Home from "./Pages/Home/Home";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ErrorPage from "./Pages/ErrorPage";
import AboutPage from "./Pages/About/AboutPage";
import Consulting from "./Pages/Consulting/Consulting";

import CommingSoon from "./Pages/ComingSoon";
import LifeBizcoaching from "./Pages/ServicePage/360_Life_Biz_coaching";
import SME_consultancy_solution from "./Pages/ServicePage/SME_consultancy_solution";
import Corporate_leadership_training from "./Pages/ServicePage/Corporate_leadership_training";
import Business_seminar from "./Pages/ServicePage/Business_seminar";

function App() {
  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div className="bg-black overflow-hidden">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<CommingSoon />} /> */}
           <Route
            exact
            path="/"
            // element={<Home video={width < 900 ? videoMobile : video} />}
            element={<Home  />}
          />
          <Route
          exact
            path="/about"
            // element={<AboutPage video={width < 900 ? videoMobile : video} />}
            element={<AboutPage  />}
          />
          <Route
            path="/360_Life_Biz_coaching"
            element={<LifeBizcoaching  />}
          />
          <Route
            path="/SME_consultancy_solution"
            element={<SME_consultancy_solution  />}
          />
          <Route
            path="/Corporate_leadership_training"
            element={<Corporate_leadership_training  />}
          />
          <Route
            path="/Business_seminar"
            element={<Business_seminar  />}
          />
          <Route
            path="/Consult"
            element={<Consulting  />}
          />
          {/* <Route path="*" element={<CommingSoon />} /> */}
          {/* <Route path="/*" element={<CommingSoon />} /> */}
          <Route path="*" element={<ErrorPage />} /> 
          {/* <Route path="*" element={<CommingSoon />} />  */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
