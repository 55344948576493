import React, { useEffect } from "react";
import HiroService from "./HiroService";

import Aboutme_Image from "./../../assets/images/architects-discussing-construction-plan-drawings 1.png";
import hiro_Img from "./../../assets/image-soheil/IMG_3700.jpeg";

import hiro_I0 from "./../../assets/image-soheil/IMG_0069.JPG";
import hiro_I1 from "./../../assets/image-soheil/IMG_3700.jpeg";
import hiro_I2 from "./../../assets/image-soheil/IMG_0087.JPG";
import hiro_I3 from "./../../assets/image-soheil/IMG_3749.JPG";
import hiro_I4 from "./../../assets/image-soheil/IMG_0065.JPG";
import hiro_I5 from "./../../assets/image-soheil/IMG_0072.JPG";

import Footer from "../Home/Footer";
import ContactMe from "../Home/ContactMe";
import ServiceCard from "../Home/Components/ServiceCard";

import aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

const steps = [
  {
    title: "step1",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step2",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step3",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
];

function LifeBizcoaching({ video }) {
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div data-aos="fade-down">
      <HiroService
        img={hiro_I0}
        title={"360 Life-Biz Coaching"}
        tag="more360"
      />
      <div
        className="md:w-9/12 w-11/12 mx-auto text-center md:pt-8 pt-0"
        id="more360"
      >
        <div className="img-container flex-col items-center md:my-8 md:w-8/12 w-11/12 mx-auto flex justify-center">
          <div className="mb-8">
            <p
              className="text-gold md:mb-16 mb-6 text-lg  capitalize text-left "
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              A one-stop solution for all your needs, starting from your
              business to your lifestyle.
            </p>
            <p
              className="text-white mb-8 md:text-base text-sm text-justify "
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              True leadership has a lot to do with how a person looks as well as
              how they feel from within. It is understood that in order to be a
              good leader, you must master the skills of being a good speaker.
              Most of the time, the focus would be on business and getting
              someone to lead, but here we will talk about things that might
              have been left out of other coaching sessions. Personal factors to
              overcome, ways to be a great speaker, and mastering the inner
              qualities of a leader will be studied in depth to make sure that
              the message can be delivered in the most powerful and effective
              way.
            </p>
            <p
              className="text-white md:text-base text-sm text-justify  "
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              Thus, many parts of your life, such as being in the best physical
              and mental shape possible, are taken into account. A healthy and
              sound mind is necessary for making the right decisions. It
              includes having a good outlook, as a great leader earns the trust
              of their employees with proper grooming and a good wardrobe. By
              carrying out all that is listed above, a true leader can earn the
              trust of their people with no hesitation.
            </p>
          </div>
          <div>
            <div className="md:grid hidden grid-cols-2 gap-10 mb-16 -mt-16 md:mt-4">
              <div className="mt-24 flex flex-col gap-10 ">
                <img
                  data-aos="fade-up"
                  // data-aos-delay="200"
                  className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                  src={hiro_I5}
                />
                <img
                  data-aos="fade-up"
                  // data-aos-delay="200"
                  className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                  src={hiro_I1}
                />

                <img
                  data-aos="fade-up"
                  // data-aos-delay="200"
                  className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                  src={hiro_I2}
                />
              </div>
              <div className="mt-8 flex flex-col gap-10">
                <img
                  data-aos="fade-up"
                  // data-aos-delay="200"
                  className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                  src={hiro_I0}
                />
                <img
                  data-aos="fade-up"
                  // data-aos-delay="200"
                  className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                  src={hiro_I3}
                />

                <img
                  data-aos="fade-up"
                  // data-aos-delay="200"
                  className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                  src={hiro_I4}
                />
              </div>
            </div>
            <div className="md:hidden grid grid-cols-1 gap-8 mb-16 -mt-0 md:mt-4">
              <img
                data-aos="fade-up"
                // data-aos-delay="200"
                className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                src={hiro_I5}
              />
              <img
                data-aos="fade-up"
                // data-aos-delay="200"
                className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                src={hiro_I0}
              />

              <img
                data-aos="fade-up"
                // data-aos-delay="200"
                className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                src={hiro_I1}
              />
              <img
                data-aos="fade-up"
                // data-aos-delay="200"
                className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                src={hiro_I3}
              />

              <img
                data-aos="fade-up"
                // data-aos-delay="200"
                className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                src={hiro_I2}
              />

              <img
                data-aos="fade-up"
                // data-aos-delay="200"
                className="mx-auto w-full md:grayscale hover:grayscale-0 transition-all duration-300"
                src={hiro_I4}
              />
            </div>

            {/* <div className="w-0 md:h-96 h-40 border-l-2 border-white border-opacity-60" /> */}
          </div>

          <div
            className="other w-full mt-2"
            data-aos="fade-up"
            // data-aos-delay="300"
          >
            <h3
              className="text-gold md:text-2xl text-xl text-left uppercase"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              1) Business coaching
            </h3>
            <p
              className=" text-white md:text-base text-sm  md:mt-6 mt-2 text-justify "
              data-aos="fade-up"
              // data-aos-delay="400"
            >
              An MBA can open the door to new opportunities. In this section,
              you will be subjected to the same syllabus as a mini MBA, with the
              exception of receiving a fully customised syllabus that is
              designed for your needs. You will be taught everything you need to
              know to be a thriving manager, business owner, and
              entrepreneur—all while expanding on your skills and forming a
              great network to fuel your success.
            </p>
          </div>
          <div
            className="other w-full mt-12"
            data-aos="fade-up"
            // data-aos-delay="300"
          >
            <h3
              className="text-gold md:text-2xl text-xl text-left uppercase"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              2) Personal training
            </h3>
            <p
              className=" text-white md:text-base text-sm  md:mt-6 mt-2 text-justify "
              data-aos="fade-up"
              // data-aos-delay="400"
            >
              Why personal training? As a leader, a strong mind comes with a fit
              body. And for that reason, this section will focus on achieving
              your dream physique and optimising your workout routine with my
              guidance as a master trainer. By creating a healthy regimen that
              includes a personalised gym routine tailored to your specific
              goals and strength levels, you will attain new levels of energy
              and fitness like never before. This includes working on one's
              posture, as this shows subtly that one is the most powerful
              person, or "alpha," in the group.
            </p>
          </div>
          <div
            className="other w-full mt-12"
            data-aos="fade-up"
            // data-aos-delay="300"
          >
            <h3
              className="text-gold md:text-2xl text-xl text-left uppercase"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              3) Nutritional advisory
            </h3>
            <p
              className=" text-white md:text-base text-sm  md:mt-6 mt-2 text-justify "
              data-aos="fade-up"
              // data-aos-delay="400"
            >
              They say you are what you eat, so let's get you eating, feeling,
              and looking your best. If you ever find yourself lacking focus and
              having a hard time concentrating, then perhaps the missing piece
              you have been searching for is a good diet. Our daily performance
              is directly linked to what we eat, so it's important for leaders
              to stay on their feet by eating healthy, well-balanced meals. Feel
              a surge in productivity when you start paying attention to the
              variety of food you eat on a daily basis under a diet plan crafted
              especially for you.
            </p>
          </div>
          <div
            className="other w-full mt-12"
            data-aos="fade-up"
            // data-aos-delay="300"
          >
            <h3
              className="text-gold md:text-2xl text-xl text-left uppercase"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              4) Public speaking
            </h3>
            <p
              className=" text-white md:text-base text-sm  md:mt-6 mt-2 text-justify "
              data-aos="fade-up"
              // data-aos-delay="400"
            >
              A good quality of a leader is how he presents himself, and that
              includes managing the tricks of taking command of the stage. If
              you fear standing before a group of executives and having them
              look at you with bored, glossy eyes, this is your chance to get
              rid of it, as a good quality of a leader is how they present
              themselves in front of people. Discover the techniques and
              strategies of studying the crowd, conquering the stage, and how to
              deliver a great speech by striking a chord with your audience.
            </p>
          </div>
          <div
            className="other w-full mt-12"
            data-aos="fade-up"
            // data-aos-delay="300"
          >
            <h3
              className="text-gold md:text-2xl text-xl text-left uppercase"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              5) Personal grooming and styling
            </h3>
            <p
              className=" text-white md:text-base text-sm   md:mt-6 mt-2 text-justify "
              data-aos="fade-up"
              // data-aos-delay="400"
            >
              <p>
                To be respected and seen as a leader, your wardrobe must reflect
                who you are and the message you are about to convey. You need to
                set an example for your team and have them identify their
                leader. Learn the tips and tricks of the psychology of colours
                and even the ways of dressing, which can unknowingly influence
                the atmosphere of your workplace once you have set foot there.
              </p>
              <p>
                Good etiquette is the result of a combination of politeness,
                conversational abilities, the ability to hold proper cutlery
                while eating, and to walk gracefully. In the presence of senior
                executives, the finer aspects of dining are frequently examined
                in precise detail, which exudes an unspoken truth about your
                upbringing and character. You'll feel confident enough to attend
                those dinner meetings without worrying about missing out on the
                finer points after taking this course.
              </p>
            </p>
          </div>
          <div
            className="other w-full mt-12"
            data-aos="fade-up"
            // data-aos-delay="300"
          >
            <h3
              className="text-gold md:text-2xl text-xl text-left uppercase"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              6) Personal branding
            </h3>
            <p
              className=" text-white md:mt-6 mt-2 md:text-base text-sm text-justify "
              data-aos="fade-up"
              // data-aos-delay="400"
            >
              Personal branding is the key to justifying your integrity as a
              figure of influence. Trust is the foundation upon which your brand
              is built, which is why it should remain your utmost priority. If
              you want to be respected and admired by those under your
              leadership, your personal brand needs to reflect that. As you
              establish this sense of trust, you will soon find that your
              business is thriving. In your area of expertise, include aspects
              that are unique and show who you are, as well as your ideals,
              principles, and beliefs.
            </p>
          </div>
        </div>
        {/* <div className="md:w-10/12 w-11/12 mx-auto">
          <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-y-4  text-left">
            {steps.map((item, index) => (
              <ServiceCard key={index} data={item} action={false} />
            ))}
          </div>
        </div> */}
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%] md:mt-0 mt-16">
        <ContactMe />
      </div>

      <Footer />
    </div>
  );
}

export default LifeBizcoaching;
