import React, { useEffect } from "react";
import ContactMe from "../Home/ContactMe";
import Footer from "../Home/Footer";

import Aboutme_Image from "./../../assets/image-soheil/IMG_0062.JPG";
import hiro_image from "./../../assets/image-soheil/IMG_0088.JPG";
import HiroAboutPage from "./HiroAboutPage";

import aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

function AboutPage({ video }) {
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className=" relative" data-aos="fade-down">
      <HiroAboutPage img={hiro_image} tag='moreAbout'/>
      <div className="md:w-9/12 w-11/12 mx-auto text-center md:mt-24 mt-12" id="moreAbout">
        <div className="img-container flex-col items-center my-8">
          <img
            data-aos="fade-left"
            // data-aos-delay="100"
            className="mx-auto"
            src={Aboutme_Image}
            width="350"
          />
        </div>
        <div className="text-center" data-aos="fade-up" data-aos-delay="300" >
          <div
            className="text-gold text-base uppercase space5"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            KNOW ME MORE

          </div>
          <h2
            className="md:text-5xl text-4xl  text-white uppercase mt-3"
            data-aos="fade-up"
            // data-aos-delay="500"
          >
            MY STORY
          </h2>
          <div className="" data-aos="fade-up" data-aos-delay="500">
            <p
              className="md:text-base text-sm text-white text-justify md:w-10/12 w-11/12 mt-7 mx-auto"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              In my own life, I had my fair share of barriers awaiting. Like
              most parents, mine had laid out the ideal career path for me—to
              follow in their footsteps in academia as they had done. However,
              much to their despair, I was determined to make a living of my own
              choosing. Despite the objections of my parents and the doubts of
              my peers, who deemed me too inexperienced to uphold a leadership
              position, I landed a job as a general manager, all at the age of
              20. My path to the position of Chief Strategy Officer, which I
              hold to this day, began with those modest beginnings. By taking a
              life-altering risk, the same success that happened to me can
              happen for you.
            </p>
            <p
              className="md:text-base text-white md:w-10/12 w-11/12 mt-7 mx-auto text-justify text-sm"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              For almost 10 years, a list of internationally diverse companies
              from Southeast Asia, the Middle East, and Canada have recognised
              me as a valuable asset after I served the position of a senior
              executive. Since 2018, my clients have come from a background of
              CEOs and other high ranking executives who sought my assistance in
              my coaching and leadership programs once they saw a distinctive
              difference in their company’s performance after my involvement.
            </p>
            <p
              className="md:text-base text-white md:w-10/12 w-11/12 mt-7 mx-auto text-justify text-sm"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              Being no stranger to the stage, I have been invited to lead a
              number of seminars after being asked to impart my knowledge in the
              academic and business worlds. The main focus of my talks has been
              specialisation in leadership training and development, as that is
              the main gap in the industry that requires our main focus. I have
              also led corporate training for management teams at multiple
              international companies to come up with strategic ways to boost
              company morale and productivity.
            </p>
            <p
              className="md:text-base text-white  md:w-10/12 w-11/12 mt-7 mx-auto text-justify text-sm"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              My vast knowledge and experience in the business field are due to
              my being an MBA graduate and currently a PhD candidate in
              International Relations and Political Science. It has enabled me
              to achieve my goal of inspiring people to be the best versions of
              themselves and assisting them in reaching their goals.Because of
              this, I've been able to help others and motivate them to improve
              themselves.
            </p>
            <p
              className="md:text-base text-white md:w-10/12 w-11/12 mt-7 mx-auto text-justify text-sm"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              In addition to that, I upheld the position of senior manager in a
              well-established commercial fitness chain after graduating with a
              degree in Sports Science, which enables me to be an all-rounder in
              tackling the aspects of training future leaders in not only their
              careers but their health and wellness levels. I have been a
              certified fitness coach in the health and fitness industry after
              earning the title of “Master Trainer,” which has allowed me to
              connect with my clients on their lifestyle journeys as well.
            </p>
            <p
              className="md:text-base text-white md:w-10/12 w-11/12 mt-7 mx-auto text-justify text-sm"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              My life has always heavily revolved around coaching. I once
              realised that it brought me immeasurable joy to help others
              accomplish their goals. It struck me that this was the beginning
              of a soon-to-be lifelong passion when I embarked on my first
              journey by helping my very close friend overcome his struggle with
              obesity. With my degree in Sports Science and my experience as a
              personal trainer, in less than 6 months under my watchful eye and
              guidance, he not only lost 20 kg but also underwent a complete
              change in his mental health.
            </p>
            <p
              className="md:text-base text-white md:w-10/12 w-11/12 mt-7 mx-auto text-justify text-sm"
              // data-aos="fade-left"
              // data-aos-delay="100"
            >
              I now realise that I have unlocked a talent within myself that
              enables me to help as many aspiring business owners as possible.
              It is abundantly clear that senior executives need to be healthy
              and fit to be respected as good leaders, and I can help them
              realise the untapped potential they've had all along. Hence, this
              is a call to action for all business owners, C executives, senior
              executives, and aspiring entrepreneurs to take control of their
              life outcomes and embark on this road of self-discovery
            </p>
          </div>

          {/* <div className="md:w-8/12 w-11/12 mx-auto mt-12 border-t-2 border-opacity-60 border-white flex flex-col items-center gap-0 ">
            <div className="md:h-40 h-20 border-l-2 border-white w-0 border-opacity-60" />
            <div className="flex flex-col gap-6">
              <img src={cert1} data-aos="fade-up" data-aos-delay="200" />
              <img src={cert2} data-aos="fade-up" data-aos-delay="200" />
              <img src={cert3} data-aos="fade-up" data-aos-delay="200" />
            </div>
          </div> */}
        </div>
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%] md:mt-0 mt-24">
        <ContactMe />
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;
