import React, { useEffect } from "react";
import HiroService from "./HiroService";

import Aboutme_Image from "./../../assets/images/architects-discussing-construction-plan-drawings 1.png";
import Hiro_Image from "./../../assets/image-soheil/IMG_0071.JPG";
import Footer from "../Home/Footer";
import ContactMe from "../Home/ContactMe";
import ServiceCard from "../Home/Components/ServiceCard";

import aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

const steps = [
  {
    title: "step1",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step2",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
  {
    title: "step3",
    description: `Built your own Squarespace site and need some help? Bring me in for
          one-on-one training, feature requests, or just to answer your
          questions.`,
  },
];

function Corporate_leadership_training({ video }) {
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  // scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div data-aos="fade-down">
      <HiroService
        img={Hiro_Image}
        title={"Corporate leadership training"}
        tag="clt"
      />
      <div
        className="md:w-9/12 w-11/12 mx-auto text-center md:pt-8 pt-8"
        id="clt"
      >
        <div className="img-container flex-col items-center md:my-8 mt-0 md:w-8/12 w-11/12 mx-auto flex justify-center">
          <div className="mb-8 ">
            <p
              className="text-gold md:mb-16 mb-6 text-lg md:text-justify text-left capitalize "
              data-aos="fade-up"
              // data-aos-delay="100"
            >
              An in depth training program to help scale up your leadership team
            </p>
            <p
              className="text-white mb-8  md:text-base text-sm text-justify  "
              data-aos="fade-up"
              // data-aos-delay="100"
            >
              Training employees to become leaders is just one of the many ways
              you can improve your team's morale and productivity. By organising
              workshops, events, and campaigns, you can subtly convey to your
              staff that you value and recognise their efforts. It allows a
              leadership team to be built from the ground up with long-term
              employees, and it improves corporate culture for the better.
            </p>
            <p
              className="text-white  md:text-base text-sm text-justify   "
              data-aos="fade-up"
              // data-aos-delay="100"
            >
              It has been proven that corporate activities can solidify a
              positive work culture and create wholesome traditions that can be
              implemented for years to come. Have strategic activities mapped
              out that will strengthen team cohesion and build trust among one
              another. This would also enable a cross-section of company levels
              and departments to interact with one another. Lastly, it will
              serve as the perfect opportunity to instil a company’s core values
              in the workplace among its employees.
            </p>
          </div>
          {/* <img
            data-aos="fade-up"
            data-aos-delay="200"
            className="mx-auto w-full"
            src={Aboutme_Image}
          />

          <div className="w-0 md:h-96 h-40 border-l-2 border-white border-opacity-60" /> */}
        </div>
        {/* <div className="md:w-10/12 w-11/12 mx-auto">
          <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-y-4  text-left">
            {steps.map((item, index) => (
              <ServiceCard key={index} data={item} action={false} />
            ))}
          </div>
        </div> */}
      </div>
      <div className="md:w-9/12 w-11/12 mx-auto pb-[0%] md:mt-0 mt-16">
        <ContactMe />
      </div>

      <Footer />
    </div>
  );
}

export default Corporate_leadership_training;
