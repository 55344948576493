import React, { useEffect } from "react";

import logo from "./../assets/images/SOHEIL-logo.svg";
import { BiMenuAltRight } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";

import * as Scroll from "react-scroll";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";

function Header2(props) {
  const { burger } = props;
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect((_) => {
    const handleScroll = (_) => {
      if (window.pageYOffset > 0) {
        setShowModal(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return (_) => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* mobile */}
      <div
        className={`fixed z-50 bg-black transition-all bg-opacity-90  top-0 h-screen w-screen bottom-0  ${
          showModal ? " right-0 duration-300 " : "right-[100vw] duration-0"
        }`}
      >
        <div
          className={`flex flex-col gap-10 justify-center items-center text-white pt-2  `}
        >
          <div className="py-6">
            <div className="">
              <img src={logo} width="200" />
            </div>
            <div className="absolute right-8 top-10">
              <IoMdClose
                onClick={() => setShowModal(false)}
                size={30}
                className="text-gold"
              />
            </div>
          </div>
          <Link to="/" className="text-white text-base font-thin mb-0"></Link>
          <ScrollLink
            to="hiro"
            duration={500}
            className=" text-white hover:scale-125 transition-all duration-300 text-xl cursor-pointer"
            spy={true}
            smooth={true}
            onClick={() => setShowModal(false)}
          >
            HOME
          </ScrollLink>
          <ScrollLink
            to="AboutMe"
            duration={500}
            spy={true}
            smooth={true}
            className=" text-white hover:scale-125 transition-all duration-300 text-xl cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            ABOUT ME
          </ScrollLink>
          {/* <ScrollLink
            to="Projects"
            duration={500}
            spy={true}
            smooth={true}
            className=" text-white hover:scale-125 transition-all duration-300 text-3xl cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            PROJECT
          </ScrollLink> */}

          <ScrollLink
            to="Service"
            duration={500}
            spy={true}
            smooth={true}
            className=" text-white hover:scale-125 transition-all duration-300 text-xl cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            SERVICES
          </ScrollLink>

          <ScrollLink
            to="contactMe"
            duration={500}
            spy={true}
            smooth={true}
            className=" text-white hover:scale-125 transition-all duration-300 text-xl cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            CONTACT
          </ScrollLink>
          {/* <ScrollLink
            to="blogs"
            duration={500}
            spy={true}
            smooth={true}
            className=" text-white hover:scale-125 transition-all duration-300 text-3xl cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            BLOG
          </ScrollLink> */}
        </div>
      </div>

      {/*  desktop */}
      <div className="flex justify-center gap-4 py-8 items-center absolute w-full top-0 z-40">
        <div className="md:hidden block"></div>
        
        <Link to="/" className="text-white text-base font-thin">
          <img src={logo} width="200" />
        </Link>
        
        <div className="text-gold md:hidden inline-block">
          {burger && <BiMenuAltRight size={35} onClick={handleShowModal} />}
        </div>
        <div></div>
      </div>
    </>
  );
}

export default Header2;
