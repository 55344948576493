import React, { useEffect } from "react";

import BigButton from "../../Componenets/BigButton";

import aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function AboutMe(props) {
  const { img, paddingTopn, aboutRef } = props;
  useEffect(() => {
    aos.init({ duration: 500 });
  }, []);

  return (
    <div
      className={`grid md:grid-cols-3  grid-cols-1 md:gap-16 gap-0 items-center md:pt-20 pt-0 relative `}
      data-aos="fade-in"
    >
      <div
        className="md:col-span-1  h-full "
        data-aos="fade-right"
        data-aos-delay="0"
      >
        <img
          src={img}
          className="md:w-full  mx-auto md:mb-0 mb-5 md:sticky top-2 md:top-10"
        />
      </div>
      <div className="text-white col-span-2  md:px-0 " data-aos="fade-out">
        <div
          data-aos="fade-in"
          className="md:relative sticky top-0 py-0 pt-8 -mt-8 bg-black z-20"
        >
          <div
            className="text-gold uppercase wide10px md:text-base text-sm"
            data-aos="fade-left"
            // data-aos-delay="100"
          >
            discover
          </div>
          <h2
            className="md:text-5xl text-2xl wide10px uppercase mt-0 md:mt-2 tracking-wide text-white"
            data-aos="fade-left"
            // data-aos-delay="200"
          >
            about me
          </h2>
        </div>
        <div
          className="mt-5"
          data-aos="fade-left"
          // data-aos-delay="500"
        >
          <p
            className="md:text-lg text-sm font-light mb-5 text-justify "
            // data-aos="fade-left"
            // data-aos-delay="500"
          >
            There are numerous leadership qualities that have gone to great
            lengths to be recognised and distinguished. Bold, visionary,
            inspirational—you name it. Good leaders go hand in hand with a
            successful and thriving workplace, as without proper leadership,
            there is no purpose or guidance for an organisation to reach its
            mark.
          </p>
          <p
            className="md:text-lg text-sm font-light mb-5 text-justify"
            // data-aos="fade-left"
            // data-aos-delay="500"
          >
            I've been assisting people for over ten years after upholding
            various senior executive positions, thus allowing me to witness
            their personal and professional growth upfront. I realised that
            without good leadership, there is a big gap in an organisation where
            a company cannot meet its full potential. By raising up the right
            leaders, they would not only inspire their subordinates, but also
            gain the respect of their employees, which would lead to an increase
            in overall work performance and job satisfaction, bringing a sense
            of clarity within a company.
          </p>
          <p
            className="md:text-lg text-sm font-light mb-5 text-justify"
            // data-aos="fade-left"
            // data-aos-delay="500"
          >
            Great leaders acknowledge that in order to break through to their
            leadership potential, they will need to take risks. In life, there
            are always barriers that taunt us into staying in someone else’s
            shadow. Rid yourselves of the “That can never be me” mentality and
            embrace a new mindset that will help you achieve your goals. And for
            that, I am here to assist you in the journey of you achieving your
            goals and dreams in the best way made possible.
          </p>
        </div>
        <Link to="/about">
          <div
            data-aos="fade-left"
            className=" mx-auto flex justify-center md:inline-block"
          >
            <BigButton text="Know me more" />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AboutMe;
